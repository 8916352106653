const colors = {
  celestialblue: '#4B99C1',
  lightblue: '#95CCF2',
  aliceblue: '#EFF7FB',
  orange: '#F5A623',
  olivergreen: '#189B4A',
  green: '#1CC26D',
  firebrick: '#B81D24',
  deepblue: '#203760',
  arsenic: '#414141',
  jet: '#353535',
  gray: '#979797',
  lightgray: '#DADADA',
  snow: '#F9F9F9',
  white: '#FFFFFF',
  transparent: 'transparent',
}

const breakpoints = {
  small: 768,
  large: 1120,
}

const gutters = {
  small: 12,
  medium: 18,
  large: 24,
  extraLarge: 30,
}

const radius = {
  medium: 43,
}

const queries = {
  small: `@media (max-width: ${breakpoints.small}px)`,
  medium: `@media (min-width: ${breakpoints.small + 1}px)`,
  large: `@media (min-width: ${breakpoints.large}px)`,
}

export default { colors, breakpoints, gutters, radius, queries }
