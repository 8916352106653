import thin from '../../fonts/montserrat-thin.ttf'
import light from '../../fonts/montserrat-light.ttf'
import regular from '../../fonts/montserrat-regular.ttf'
import italic from '../../fonts/montserrat-italic.ttf'
import medium from '../../fonts/montserrat-medium.ttf'
import mediumItalic from '../../fonts/montserrat-medium-italic.ttf'
import semibold from '../../fonts/montserrat-semibold.ttf'
import bold from '../../fonts/montserrat-bold.ttf'
import above from '../../fonts/above.ttf'
import bradley from '../../fonts/bradley-hand.ttf'
import mostraNuova from '../../fonts/mostra-nuova.otf'

export default `

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 100;
  src: url(${thin});
  src: url(${thin}) format("truetype"), url(${`${thin}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 300;
  src: url(${light});
  src: url(${light}) format("truetype"), url(${`${light}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 400;
  src: url(${regular});
  src: url(${regular}) format("truetype"), url(${`${regular}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: italic;
  font-weight: 400;
  src: url(${italic});
  src: url(${italic}) format("truetype"), url(${`${italic}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 500;
  src: url(${medium});
  src: url(${medium}) format("truetype"), url(${`${medium}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 500;
  src: url(${medium});
  src: url(${medium}) format("truetype"), url(${`${medium}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: italic;
  font-weight: 500;
  src: url(${mediumItalic});
  src: url(${mediumItalic}) format("truetype"), url(${`${mediumItalic}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 600;
  src: url(${semibold});
  src: url(${semibold}) format("truetype"), url(${`${semibold}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "MontSerrat";
  font-style: normal;
  font-weight: 700;
  src: url(${bold});
  src: url(${bold}) format("truetype"), url(${`${bold}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "Above";
  font-style: normal;
  font-weight: normal;
  src: url(${above});
  src: url(${above}) format("truetype"), url(${`${above}?#iefix`}) format('embedded-opentype');
}


@font-face {
  font-display: swap;
  font-family: "Above";
  font-style: normal;
  font-weight: normal;
  unicode-range: U+0030-0039;
  src: url(${regular});
  src: url(${regular}) format("opentype"), url(${`${regular}?#iefix`}) format('embedded-opentype');
}

@font-face {
  font-display: swap;
  font-family: "Bradley";
  font-style: normal;
  font-weight: 400;
  src: url(${bradley});
  src: url(${bradley}) format("truetype"), url(${`${bradley}?#iefix`}) format('embedded-opentype');
}


html, body, button, input {
  font-family: "MontSerrat", Arial, Helvetica, sans-serif !important;
}
`
