import axios from 'axios'

const API = axios.create({
  baseURL: 'https://sistema.denario.app/api',
  // baseURL: 'https://homol.sistema.denario.app/api',
  responseType: 'json',
})

API.interceptors.request.use(config => {
  config.headers['Allow-Cross-Origin'] = '*'
  config.headers['Access-Control-Allow-Origin'] = '*'
  config.headers['Access-Control-Allow-Headers'] =
    'Origin, X-Requested-With, Content-Type, Accept'

  return config
})

export default API
